<template>
  <div>
    <div class="container-fluid">
      <div class="row mt-4">
        <div class="col-lg-5 col-md-5 col-sm-12 mt-3">
          <div class="card">
            <div class="card-body">
              <p>
                <span class="d-flex align-items-center gap-2 card_left_text"><img src="../../assets/img/attendence.png"
                    alt="birthday" /><span style="margin-left: 8px;"> Attendance</span></span>

                <span class="veiw_all">
                  <a style="color: blue" @click.prevent="navToAttendance">View All</a></span>
              </p>
              <br>
              <div class="row">
                  <div class="col-lg-3 col-md-6" style="margin-top: 14px;padding-left: 25px;">
{{  timestamp }}
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    style="margin-top: 14px;text-align: center;"
                  >
                  Status: {{ clockOutBody && clockOutBody.status ? clockOutBody.status == 'Missing Swipe' ? 'Clock In' : clockOutBody.status : 'Absent'}}
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="cin_cout">
                  <div class="card inner_card" style="border-radius: 9px;width: 103px;padding: 0px;">
                    <!-- <div class="icon"><img src="../../assets/img/Group.svg" class="img-fluid svg_img"></div> -->
                    <span class="chec_kin"><a href="javascript:void(0)" style="font-size: smaller;">Check In</a> <br /></span>
                    <span class="day">{{ clockedInTime != null ? clockedInTime : "-- : --" }}</span>
                  </div>
                  
                </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 4px;">
                  <div class="col-lg-4 col-md-6 col-sm-12" style="padding-left: 22px;">
                    <span class="date">{{ fullTimeStamp }}</span>
                    <br />
                  <span class="day" style="padding-left: 24px;" v-if="fullTimeStampDay"> ({{ fullTimeStampDay }})</span>
                  </div>
                  <div
                    class="col-lg-5 col-md-6 col-sm-12"
                    style="text-align: center"
                  >
                  <div class="clock_btn" style="text-align: start">
                    <button type="button" class="btn btnsml" id="svbtn" style="margin-left: 8px;margin-top: 8px;width: 70%;"
                  :disabled="clockOutBody.status == 'Leave' || clockOutBody.status == 'Pending Approval' || (clockedInTime && clockedOutTime) || showloader"
                    v-if="!isClockedIn" @click.prevent="clockIn">
                    <span>Check-In</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>
 
                  <button type="button" class="btn btnsml" id="svbtn" style="margin-left: 8px;margin-top: 8px;width: 70%;"
                    v-if="isClockedIn"
                    @click.prevent="clockOut">
                    <span>Check-Out</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>
 
               
                </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="cin_cout">
                      <div class="card inner_card" style="border-radius: 9px;padding: 0px;">
                    <!-- <div class="icon"><img src="../../assets/img/Group 7.png" class="img-fluid"></div> -->
                    <span class="chec_kin"><a href="javascript:void(0)" style="font-size: smaller;">Check Out</a></span>
                    <span class="day">{{ clockedOutTime != null ? clockedOutTime : "-- : --" }}</span>
                  </div>
                  
                </div>
                  </div>
                </div>
               
                <!-- <div class="attend_time d-flex flex-wrap justify-content-between">
                <div class="calender d-flex flex-column ">
                  <span class="time mb-2">{{ timestamp }}</span>
                  <span class="date">{{ fullTimeStamp }}</span>
                  <span class="day"> {{ fullTimeStampDay }}</span>
                </div>

                <div class="clock_btn">
                  <button type="button" class="btn btnsml" id="svbtn" style="width: 104%;"
                  :disabled="clockOutBody.status == 'Leave' || clockOutBody.status == 'Pending Approval' || (clockedInTime && clockedOutTime) || showloader"
                    v-if="!isClockedIn" @click.prevent="clockIn">
                    <span>Clock In</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>

                  <button type="button" class="btn btnsml" id="svbtn" style="width: 104%;"
                    v-if="isClockedIn" 
                    @click.prevent="clockOut">
                    <span>Clock Out</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>
                  </button>

               
                </div>
              
                <div class="cin_cout">
                  <div class="card inner_card" style="border-radius: 9px;">
                    <span class="chec_kin"><a href="javascript:void(0)">Clock In</a></span>
                    <span class="day">{{ clockedInTime != null ? clockedInTime : "-- : --" }}</span>
                  </div>
                  <div class="card inner_card" style="border-radius: 9px;">
                    <span class="chec_kin"><a href="javascript:void(0)">Clock Out</a></span>
                    <span class="day">{{ clockedOutTime != null ? clockedOutTime : "-- : --" }}</span>
                  </div>
                </div>
                <span style="position: absolute;
    top: 142px;
    right: 168px;">Status: {{ clockOutBody.status == 'Missing Swipe' ? 'Clock In' : clockOutBody.status }}</span>
              </div> -->
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-body">
              <p>
                <span class="d-flex align-items-center gap-2 card_left_text"><img src="../../assets/img/attendence.png"
                    alt="birthday" /><span style="margin-left: 3px;"> Attendance</span></span>

                <span class="veiw_all">
                  <a style="color: blue" @click.prevent="navToAttendance">View All</a></span>
              </p>
              <br>
              <p>
                <span class="d-flex align-items-center gap-2 card_left_text" style="padding-right: 9px;"><span style="margin-left: 3px;"> {{ timestamp }}</span></span>
                <span class="d-flex align-items-center gap-2 card_left_text" style="padding-right: 9px;"><span style="margin-left: 3px;"> {{ fullTimeStamp }}</span></span>
                <span class="d-flex align-items-center gap-2 card_left_text" style="padding-right: 9px;"><span style="margin-left: 3px;"> {{ fullTimeStampDay }}</span></span>
                <span class="d-flex align-items-center gap-6 card_left_text" style="padding-right: 9px;">

                

                </span>
              </p>
                <div class="row mt-4">
        <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div class="clock_btn">
                  <button class="btn" style="background-color: var(--button-bg);border-radius: 28px;"
                    :disabled="clockOutBody.status == 'Leave' || clockOutBody.status == 'Pending Approval' || (clockedInTime && clockedOutTime) || showloader"
                    v-if="!isClockedIn" @click.prevent="clockIn">
                    
                   
                    <span>Clock In</span> <i v-if="showloader" class='fa fa-spinner fa-spin'></i>

                  </button>
                  <button class="btn" style="background-color: var(--button-bg);border-radius: 28px;" v-if="IsClockedIn"
                    @click.prevent="clockout">
                    <span><i v-if="showloader" class='fa fa-spinner fa-spin'></i>clockout</span> 

                    </button>
                </div>
              </div>
            </div>

               
             
             
            
            </div>
          </div> -->
          <div class="card mt-3">
            <div class="card-body">
              <p>
                <span class="d-flex align-items-center gap-2 card_left_text"><img src="../../assets/img/birthday.png"
                    alt="birthday" /> <span style="margin-left: 8px;"> Birthdays</span></span>

                <span class="veiw_all">
                  <a style="color: blue;text-align: end" @click.prevent="openBirthdaypopup">View All</a>
                </span>
              </p>
              <div class="t_body">
                <table class="table birthday_table text-center">
                  <thead>
                    <tr>
                      <th>Student</th>
                      <th>DOB</th>
                      <th>Class</th>
                      <th>Classroom</th>
                    </tr>
                  </thead>

                  <tbody>
                    <!-- Add your table rows here -->
                    <tr v-for="(student, index) in birthdayStudentList" :key="index">
                      <td>{{ student.firstName + " " + student.lastName }}</td>
                      <td>{{ student.dob | date }}</td>
                      <td>{{ student.class ? student.class.className : '' }}</td>
                      <td>{{ student.section ? student.section.name : '' }}</td>
                    </tr>

                  </tbody>

                </table>
              </div>
            </div>
          </div>
          <!---------------------holiday card------------------------->
          <div class="card mt-3">
            <div class="card-body">
              <p>
                <span class="d-flex align-items-center gap-2 card_left_text"><img src="../../assets/img/holidays.svg"
                    alt="birthday" /><span style="margin-left: 8px;"> Holidays</span></span>

                <span class="veiw_all">
                  <a style="color: blue;text-align: end" @click.prevent="openHolidaypopup">View All</a>
                </span>
              </p>
              <div class="t_body">
                <table class="table birthday_table">
                  <thead>
                    <tr style="border-bottom: 0px solid black;">
                      <th>Date</th>
                      <th>Name of holiday </th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="(student, index) in holidayList" :key="index" style="border-bottom: 0px solid black;">

                      <td>{{ student.date | dateFormat }}</td>
                      <td>{{ student.name }}</td>

                    </tr>

                  </tbody>
                </table>

              </div>

            </div>
          </div>
          <!--------------------------LEAVE CARD------------------------------------------->

          <!------------------------------staff counter cards --------------------->

          <!-- STUDENT MESSAGES -->
          <!-- <div class="card mt-4">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-3">
                <span class="d-flex align-items-center gap-2 card_left_text"><img src="../../assets/img/msg.svg"
                    alt="birthday" /> <span style="margin-left: 3px;"> Student Messages</span></span>
              </div>
              <div>
                <div class="form-row">
                  <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <select v-validate="'required'" class="form-control form-input" id="deptlistid" name="classvisitor"
                        v-model="messageDetails.class" @change="getSection(messageDetails.class)">
                        <option :value="null">-- Select --</option>
                        <option v-for="(item, index) in classList" :key="index" :value="item._id">
                          {{ item.className }}
                        </option>
                      </select>
                      <label v-if="errors.first('admissionValidate.classvisitor')" class="vi-error"
                        style="top: -1em">Class is required</label>
                      <label for="deptid" v-else>Select Class </label>
                    </span>
                  </div>
                  <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <select v-validate="'required'" @change="getStudentList" class="form-control form-input"
                        id="deptlistid" name="sectionvisitor" v-model="messageDetails.classRoom">
                        <option :value="null">-- Select --</option>

                        <option v-for="(item, index) in sectionList" :key="index" :value="item._id">
                          {{ item.name }}
                        </option>
                      </select>
                      <label v-if="errors.first('admissionValidate.sectionvisitor')" class="vi-error"
                        style="top: -1em">Classroom is required</label>
                      <label for="deptid" style="width: 134px;" v-else>Select Classroom</label>
                    </span>
                  </div>
                  <div class="form-group input-group col-md-4 mt-3 pr-md-3" style="margin-left: 5px;">
                    
                    <input class="form-control me-1 search_staff" v-model="studentsearchWords" type="search" placeholder="Type Name" aria-label="Search">

                 
                </div>
                </div>
              </div>

              <div class="staff_pic1 mb-2 d-flex flex-wrap">
                <div class="staff std-staff" v-for="(data, index) in studentList" :key="index" style="width: 13%;">
                  <div class="staff_pic" @click="openChat(data)">
                    <div class="std-name">{{ data.firstName.charAt(0) }}</div>
                    <div class="status-circle" style="width: 18px;
    height: 17px;
    color: white;
    font-size: 9px;" v-if="data.message.length > 0">{{ data.message.length }}</div>
                  </div>
                  <div class="std_info" @click="openChat(data)">
                    <span class="name"> {{ data.firstName }}</span>
                  </div>
                </div>


              </div>
            </div>
          </div> -->

        </div>

        <!---------------------------------Birthday Card------------------------->
        <div class="col-lg-4 col-md-4 col-sm-12 mt-3">
          <div class="card" style="margin-right: 11px;">
            <div class="card-body">
              <p class="d-flex justify-content-between">
                <span class=" align-items-center gap-2 card_left_text"><img src="../../assets/img/Group 14.svg"
                    alt="birthday" /> <span style="margin-left: 8px;"> Leaves</span></span>
                <span>

                  <!-- button class="btn">Apply Leaves</button -->
                  <button type="button" class="btn btnsml" id="svbtn" style="margin-top: 40px;width: 100%;"
                    @click.prevent="openLeavePopUp">
                    <span style="text-transform: none;">Apply Leave</span>
                  </button>
                  <!-- <button class="btn" style="background-color:#F85149" @click.prevent="openLeavePopUp">Apply Leave(s)</button> -->


                </span>

                <span class="veiw_all">
                  <a style="color: blue" @click.prevent="navToLeave">View All</a>
                </span>
              </p>
            </div>
          </div>
          <div class="container-fluid p-0 m-0" style="margin-right: 11px;">
            <div class="row mt-3" style="margin-right: 1px;">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card">
                  <div class="card-body d-flex justify-content-between">
                    <!-- <i class="fa-solid fa-arrow-right-from-bracket"></i> -->
                    <div class="">
                      <span><img style="width: 50px;" src="../../assets/img/staff in.png" alt=""></span>

                      <!-- <span><i class="fas-arrow-right-from-bracket"></i></span> -->
                    </div>
                    <div class="counter">
                      <span class="staff_in">Staff In Today</span><br>
                      <span class="staff_counter">{{ userPresentCount || 0 }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card" >
                  <div class="card-body d-flex justify-content-between">
                    <div class="">
                      <span><img style="width: 50px;" src="../../assets/img/staff in.png" alt=""></span>
                    </div>
                    <div class="counter">
                      <span class="staff_in">Not In Today</span><br>
                      <span class="staff_counter">{{ userCount || 0 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 text-center" style="margin-right: 1px;">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <div class="card" style="line-height: 50px;">
                    <p class="std_attnd_details" style="color: #333333;margin-top: 12px;cursor: pointer;font-weight: bold;font-family: poppins;"
                      @click.prevent="redirectRoute(`/student/attendance/management?fromType=view`)">Students Attendance
                      Details</p>
                    <table class="attendace_table text-center" style="margin-right: 35px;">
                      <tr class="table_head">
                        <td style=" cursor: pointer;"
                          @click.prevent="redirectRoute(`/student/attendance/management?fromType=view`)">Total Students
                        </td>
                        <td>Present</td>
                        <td>Absent</td>
                      </tr>
                      <tr class="counter mt-3">
                        <td class="staff_counter" style=" cursor: pointer;"
                          @click.prevent="redirectRoute(`/student/attendance/management?fromType=view`)">{{
                    studentTotalCount || 0 }}</td>
                        <td class="staff_counter">{{ studentPresentCount || 0 }}</td>
                        <td class="staff_counter">{{ studentAbsentCount || 0 }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                <div>
                  <div class="card p-3 ">
                    <table class="attendace_table text-center" style="margin-left: 20px">
                      <tr class="table_head">
                        <td id="leave_data1">Staff leave request</td>
                        <td class="two" id="leave_data2">Student leave request</td>
                        <td id="leave_data3">Attendance regularization</td>
                      </tr>
                      <tr class="counter text-center mt-3">
                        <td class="staff_counter">
                          <span v-if="staffLeaveApply > 0">
                            <a style="font-weight: 600;color: red;" href="javascript:void(0);"
                              @click.prevent="redirectRoute(`/admission/inbox`)">{{ staffLeaveApply }} </a>
                          </span>
                          <span v-else>{{ staffLeaveApply }}</span>
                        </td>
                        <td class="staff_counter two">
                          <span v-if="studentLeaveApply > 0">
                            <a style="font-weight: 600;color: red;" href="javascript:void(0);"
                              @click.prevent="redirectRoute(`/admission/inbox`)">{{ studentLeaveApply }} </a>
                          </span>
                          <span v-else>{{ studentLeaveApply }}</span>
                        </td>
                        <td class="staff_counter">
                          <span v-if="attendanceRegularization > 0">
                            <a style="font-weight: 600;color: red;" href="javascript:void(0);"
                              @click.prevent="redirectRoute(`/admission/inbox`)">{{ attendanceRegularization }} </a>
                          </span>
                          <span v-else>{{ attendanceRegularization }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!------------------------------------------------staff side bar------------------>
        <div class="col-lg-3 col-md-3 col-sm-6 p-0 mt-3" style="margin-left: -6px;">
          <div class="card ">
            <div class="card-body" style="border-radius: 30px;">

              <p style="text-align: center;" img src="../../assets/img/message.png" alt=""><span class="messages" style="padding: 0px;margin: 0px;color: black;
    font-weight: 600;">Messages</span></p>
              <!-- <span>Staff Message</span> -->
              <div class="tabs" style="height: 2%;background:none">
                <input type="radio" name="tabs" id="classestab" checked="checked" />
                <label for="classestab"
                  style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;margin-left: 67px;">Staff
                 </label>
                <div class="tab">
                  <input class="form-control me-1 search_staff" type="search" placeholder="Search"
                    v-model="staffSearch" aria-label="Search" style="font-size: 13px;">

                  <div class="staff d-flex" v-for="(data, index) in staffList" :key="index">
                    <div class="staff_pic" @click.prevent="openAdminChat(data)" v-if="data.userId">
                      <div class="std-name"> {{ data.userId ? data.userId.firstName.charAt(0) : "" }}</div>

                      <!-- <img src="../../assets/img/staff_img.png" > -->
                      <div class="status-circle" style="width: 18px;
    height: 17px;
    color: white;
    font-size: 9px;" v-if="data.message.length > 0">{{ data.message.length }}</div>
                    </div>
                    <div class="staff_info ms-2" @click.prevent="openAdminChat(data)" v-if="data.userId">
                      <span class="staff_name"> {{
                    data.userId
                      ? data.userId.firstName
                      : ""
                  }}</span><br>
                      <!-- <span class="staff_desig" style="margin-left: 3px;">Teacher</span> -->
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="ss" />
                <label for="ss" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Student
                  </label>
                <div class="tab">
                  <!-- <div> -->
                  <div class="form-row">
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select v-validate="'required'" class="form-control form-input" id="deptlistid"
                          name="classvisitor" v-model="messageDetails.class" @change="getSection(messageDetails.class)">
                          <option :value="null">-- Select --</option>
                          <option v-for="(item, index) in classList" :key="index" :value="item._id">
                            {{ item.className }}
                          </option>
                        </select>
                        <label v-if="errors.first('admissionValidate.classvisitor')" class="vi-error"
                          style="top: -1em">Class is required</label>
                        <label for="deptid" v-else> Class </label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select v-validate="'required'" @change="getStudentList" class="form-control form-input"
                          id="deptlistid" name="sectionvisitor" v-model="messageDetails.classRoom">
                          <option :value="null">-- Select --</option>

                          <option v-for="(item, index) in sectionList" :key="index" :value="item._id">
                            {{ item.name }}
                          </option>
                        </select>
                        <label v-if="errors.first('admissionValidate.sectionvisitor')" class="vi-error"
                          style="top: -1em">Classroom is required</label>
                        <label for="deptid" style="width: 134px;" v-else> Classroom</label>
                      </span>
                    </div>
                    <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3" style="margin-left: 5px;">
                    
                    <input class="form-control me-1 search_staff" v-model="studentsearchWords" type="search" placeholder="Type Name" aria-label="Search">

                 
                </div> -->
                  </div>
                  <div class="form-row">
                    <input class="form-control me-1 search_staff" type="search" placeholder="Type Name"
                      v-model="studentsearchWords" aria-label="Search">

                  </div>
                  <div class="staff d-flex" v-for="(data, index) in studentList" :key="index">
                    <div class="staff_pic" @click.prevent="openChat(data)">
                      <div class="std-name"> {{ data.firstName.charAt(0) }}</div>

                      <!-- <img src="../../assets/img/staff_img.png" > -->
                      <div class="status-circle" style="width: 18px;
    height: 17px;
    color: white;
    font-size: 9px;" v-if="data.message.length > 0">{{ data.message.length }}</div>
                    </div>
                    <div class="staff_info ms-2" @click.prevent="openChat(data)">
                      <span class="staff_name"> {{ data.firstName }}</span><br>
                      <!-- <span class="staff_desig" style="margin-left: 3px;">Teacher</span> -->
                    </div>
                  </div>
                  <!-- <div style="height: 350px; overflow-y: auto">
                  <div class="tet">
                  <div class="tetme">
      <div class="overlap">
        <div class="overlap-group-wrapper">
          <div class="overlap-group">
           
              <div class="div">Search for students</div>
            
            <div class="ellipse"></div>
            <div class="ellipse-2"></div>
            <div class="ellipse-3"></div>
            <div class="text-wrapper-8">Geethanjali</div>
            <div class="text-wrapper-9">Geethanjali</div>
            <div class="text-wrapper-10">Geethanjali</div>
            <div class="text-wrapper-11">10:20 am</div>
            <div class="text-wrapper-12">10:20 am</div>
            <div class="text-wrapper-13">10:20 am</div>
            <p class="p">The schedule has been updated. Pl...</p>
            <p class="text-wrapper-14">The schedule has been updated. Pl...</p>
            <p class="text-wrapper-15">The schedule has been updated. Pl...</p>
            <img class="vector" alt="Vector" src="vector.svg" />
            <img class="vector-2" alt="Vector" src="image.svg" />
            <div class="overlap-4">
              <div class="text-wrapper-16">1</div>
            </div>
          </div>
        </div>
        <div class="text-wrapper-17">G</div>
        <div class="text-wrapper-18">G</div>
        <div class="text-wrapper-19">G</div>
      </div>
                  </div>

                </div>
                 </div> -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="leaveApplyPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close" @click.prevent="closeLeavePopUp">×</button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Apply Leave</h2>
          <br />
          <div>
            <form data-vv-scope="admissionValidate" id="crtadmission" accept-charset="utf-8">
            
              <div class="form-row">
              <!-- <div class="form-group input-group col-md-1 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 66px;">Leave Type :</div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <!-- <span style="margin-top: 6px;color: #2954a2; ">Leave Type : </span> -->
                  <div style="margin-top: 1px;
    width: 88%;">
                    <label class="custradio" style="margin-left: 6px;
    margin-bottom: 0px;
    border-radius: 21px;
    width: 144px;">Planned Leave
                      <input type="radio" v-model="leaveDetails.reason" value="Planned Leave" name="schednmnmnule">
                      <span class="checkmark"></span>
                    </label>
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 110px;">Sick Leave
                      <input type="radio" v-model="leaveDetails.reason" value="Sick Leave" name="schedkkkjklllule">
                      <span class="checkmark"></span>
                    </label>
                  
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 0px;">Others
                      <input type="radio" v-model="leaveDetails.reason" value="Others" name="schedulrbvte">
                      <span class="checkmark"></span>
                    </label>

                   
                  </div>
                </div>

              </div>
              
              <div class="form-row">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 66px;">Comments : </div>

                
                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea class="form-control web-form-input"
                      style="min-height:135px;resize:none;border: 1px solid #ccc;" id="message"
                      v-model="leaveDetails.notes"></textarea>
                    <!-- <input
                      type="date"
                      v-model="leaveDetails.notes"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    /> -->

                    <!-- <label for="deptid" style="margin-top: -6px">Comments </label> -->
                  </span>
                </div>
                <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div> -->
              </div>
              <div class="form-row">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group  col-md-3 mt-3 pr-md-3" style="padding-left: 8px;">Is it half day leave? : </div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  
                  <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox"  @change="checkIsHalfDay" v-model="leaveDetails.isHalfDay" />
                    <span class="checkmarkchk" ></span>
                    
                  </label>
                </div>
                </div>
                <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                 
                </div> -->
              </div>
              <div class="form-row">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 77px;">From Date :</div>

                <div class="form-group col-md-9 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input type="date" :min="minDatesLeaveApply" :max="maxDatesLeaveApply" v-model="leaveDetails.from"
                      onkeydown="return false" @change="checkToDate" class="form-control" id="stdDob" />

                    <!-- <label for="deptid">From Date <span class="required">*</span></label> -->
                  </span>
                </div>
               
                <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div> -->

              </div>
              <div class="form-row" v-if="leaveDetails.from">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 84px;">Day Type :</div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <!-- <span style="margin-top: 6px;color: #2954a2; ">Day Type: </span> -->
                  <div style="margin-top: 1px;
    width: 90%;">
                    <label class="custradio" style="margin-left: 6px;
    margin-bottom: 0px;
    border-radius: 21px;
    width: 45px;">Full 
                      <input type="radio" v-model="leaveDetails.fromDayType" value="Full" name="scheduljkjke">
                      <span class="checkmark"></span>
                    </label>
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 88px;">Forenoon
                      <input type="radio" v-model="leaveDetails.fromDayType" value="Forenoon" name="schedurffgle">
                      <span class="checkmark"></span>
                    </label>
                  
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 0px;">Afternoon
                      <input type="radio" v-model="leaveDetails.fromDayType" value="Afternoon" name="schedulghge">
                      <span class="checkmark"></span>
                    </label>

                    
                  </div>
                </div>

              </div>
              <div class="form-row" v-if="!leaveDetails.isHalfDay && leaveDetails.from">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 96px;">To Date :</div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input type="date" :min="leaveDetails.from" :max="maxDatesLeaveApply" :disabled="!leaveDetails.from"
                      v-model="leaveDetails.to" onkeydown="return false" class="form-control" id="stdDob" />

                    <!-- <label for="deptid">To Date <span class="required">*</span></label> -->
                  </span>
                </div>
               
                <!-- <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div> -->

              </div>
              <div class="form-row" v-if="!leaveDetails.isHalfDay && leaveDetails.to && (leaveDetails.from != leaveDetails.to)">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 84px;">Day Type :</div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <!-- <span style="margin-top: 6px;color: #2954a2; ">Day Type: </span> -->
                  <div style="margin-top: 1px;
    width: 90%;">
                    <label class="custradio" style="margin-left: 6px;
    margin-bottom: 0px;
    border-radius: 21px;
    width: 45px;">Full
                      <input type="radio" v-model="leaveDetails.toDayType" value="Full" name="schedussle">
                      <span class="checkmark"></span>
                    </label>
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 88px;">Forenoon
                      <input type="radio" v-model="leaveDetails.toDayType" value="forenoon" name="schsdsdedule">
                      <span class="checkmark"></span>
                    </label>
                    
                    <label class="custradio" style="margin-bottom: 0px;
    border-radius: 21px;
    width: 0px;">Afternoon
                      <input type="radio" v-model="leaveDetails.toDayType" value="Afternoon" name="ssdsfdfchedule">
                      <span class="checkmark"></span>
                    </label>

                  </div>
                </div>
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->

              </div>
              <div class="form-row" v-if="(leaveDetails.isHalfDay && leaveDetails.from) || (!leaveDetails.isHalfDay && leaveDetails.from && leaveDetails.to)">
                <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3"></div> -->
                <div class="form-group col-md-3 mt-3 pr-md-3" style="padding-left: 66px;">No Of Days : </div>

                <div class="form-group input-group col-md-9 mt-3 pr-md-3">
                  <!-- <span>No Of Days : </span>  -->
                  <span style="margin-left: 7px;">{{ noOfDaysLeave }} </span>
                  <!-- <div>
                ssss
                </div> -->
                </div>
                <div class="form-group input-group col-md-2 mt-3 pr-md-3"></div>

              </div>
             

              <div class="widjetfooter">
                <!-- <div class="dk_icon"></div>  -->
                <!-- <button type="button" :disabled="!leaveDetails.from || noOfDaysLeave == 0" -->

                <div class="text-center dk_iconsml">
                  <button type="button" :disabled="!leaveDetails.from || (!leaveDetails.to && !leaveDetails.isHalfDay)"
                    class="btn btnsml" id="svbtn" @click="updateLeaveApply">
                    <span>Apply</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click="closeLeavePopUp">
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </b-modal>
      <b-modal id="birthDayPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close" @click.prevent="closeBirthDayPopUp">×</button>
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Birthdays</h2>
          <br />
          <div>
            <form data-vv-scope="admissionValidate" id="crtadmission" accept-charset="utf-8">
              <div class="row">
                <div class="col-lg-12">
                  <div class="widjet">
                    <div class="widjetcontent">

                      <div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell">Student</div>
                            <div class="cell">DOB</div>
                            <div class="cell">Class</div>
                            <div class="cell">Classroom</div>


                          </div>
                          <div class="resrow" v-for="(
                                  student, index
                                ) in birthdayStudentAllList" :key="index">


                            <div class="cell" data-title="Student Name">{{ student.firstName + " " + student.lastName }}
                            </div>
                            <div class="cell" data-title="Student Name">{{ student.dob | date }}</div>

                            <div class="cell" data-title="Student Name">{{ student.class ? student.class.className : ''
                              }}
                            </div>
                            <div class="cell" data-title="Student Name">{{ student.section ? student.section.name : ''
                              }}
                            </div>


                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <div class="widjetfooter" style="margin-top: 12px;">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">

                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click="closeBirthDayPopUp">
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </b-modal>
      <b-modal id="holidayPopUp" class="modal">
        <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Holidays</h2>
          <br />
          <div>
            <form data-vv-scope="admissionValidate" id="crtadmission" accept-charset="utf-8">
              <div class="row">
                <div class="col-lg-12">
                  <div class="widjet">
                    <div class="widjetcontent">

                      <div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell">Date</div>
                            <div class="cell">Name</div>


                          </div>
                          <div class="resrow" v-for="(
                                  student, index
                                ) in allHolidayList" :key="index">

                            <div class="cell" data-title="Student Name">{{ student.date | dateFormat }}</div>

                            <div class="cell" data-title="Student Name">{{ student.name }}</div>


                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <div class="widjetfooter dispflex">
                <div class="dk_icon"></div>
                <div class="text-right dk_iconsml" style="margin-top: 1%;">

                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click="closeHolidayPopUp">
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </b-modal>
      <b-modal id="messageTeacherPopUp" no-close-on-backdrop no-close-on-esc style="width: 60%">
        <div>
          <button type="button" class="close" @click.prevent="closeTeacherChat">×</button>
          <div class="contact-form">
            <!-- <span class="close" @click.prevent="closeTeacherChat">&times;</span> -->
            <h2> {{ teacherDetails.userId ? teacherDetails.userId.firstName : '' }}</h2>
            <br />

            <!-- <div class="widjet"> -->

            <!-- <div class="widjetcontent">
              <div class="mt-4 mr-2"> -->
            <div class="chat-container" id="chat-container">
              <div v-for="(group, day) in Object.keys(groupedStudentMessages)" :key="day">

                <div>
                  <div style="text-align: center"><span
                      style="border-radius: 5px;background-color: #ffeded;font-size: 12px;padding-left: 16px;padding-right: 16px;">{{
                    formatDayHeader(group)
                  }}</span></div>
                  <div v-for="message in groupedStudentMessages[group]" :key="message._id">

                    <div class="message" style="margin: 0px;" v-if="message.fromUserType == 'Admin'">
                      <div class="message sender-message" style="margin: 0px;width: 300px;margin-top: -2px">{{ message.message }}
                        <span class="timestamp">
                          {{ formatTime12Hour(message.date) }}
                        </span>
                      </div>
                    </div>
                    <div class="message receiver-message" v-else style="width: 300px;">
                      <div>{{ message.message }}</div>
                      <span class="timestamp">
                        {{ formatTime12Hour(message.date) }}
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-9">
                <textarea style="width: 100%;margin-left: -1px;" @keyup.enter="handleKeyPress" v-model="studentMessage"
                  class="message-input" placeholder="Type your message..."></textarea>


              </div>
              <div class="col-lg-2" style="text-align: center;">
                <button style="margin-top: 11px;padding: 22px;margin-left: -40px;" class="send-button"
                  @click.prevent="sendToTeacher">
                  Send
                </button>
              </div>
              <div class="col-lg-1" style="text-align: center">
                <li class="addropdown">
                  <span class="dropdown-toggle" data-bs-toggle="dropdown" data-hover="dropdown"
                    data-close-others="true">
                    <button style="margin-top: 11px; padding: 22px;margin-left: -56px;background-color: gray;"
                      class="send-button">
                      . . .
                    </button>
                  </span>
                  <div class="addropdown-content wow bounceIn">
                    <div class="arrow-up"></div>
                    <div class="addropmenu" style="background-color: #dbefe3;width: 311px;">
                      <span class="usernamemob">.sss..</span>
                      <label class="web-custradio" style="margin-right:10px;">Press enter to send message
                        <input type="radio" name="regRadio" @change="showOptions" v-model="isEnterBtnEnable"
                          value="enter" />
                        <span class="web-checkmark"></span>
                      </label>
                      <label class="web-custradio">Click send to send message
                        <input type="radio" name="regRadio" @change="showOptions" v-model="isEnterBtnEnable"
                          value="send" />
                        <span class="web-checkmark"></span>
                      </label>


                    </div>
                  </div>
                </li>

              </div>
            </div>

            <!-- <div class="chat-container" id="chat-container">
                    
                    <div v-for="(msg, index) in teacherMessageList" :key="index" >
                    <div class="message sender-message" v-if="msg.fromUserType == 'Admin'">
                        <p>{{ msg.message }}</p>
                        <div class="timestamp">{{ msg.date | dateFormat}}</div>
                      </div>

                      <div class="message receiver-message" v-else>
                        <p>{{ msg.message }}</p>
                        <div class="timestamp">{{ msg.date | dateFormat}}</div>
                      </div>
                    </div>


                      
                    </div> -->
            <!-- <div>
                        <textarea
                          style="width: 86%"
                          v-model="studentMessage"
                          class="message-input"
                          placeholder="Type your message..."
                        ></textarea
                        ><button class="send-button" @click.prevent="sendToTeacher">Send</button>
                      </div> -->
            <!-- </div>
            </div>
          </div> -->
          </div>
        </div>
      </b-modal>
      <b-modal id="messageStudentPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
        <button type="button" class="close" @click.prevent="closeStudentChat">×</button>
        <div class="contact-form">
          <!-- <span class="close" @click.prevent="closeStudentChat">&times;</span> -->
          <h2>
            {{ studentDetails.firstName + " " + studentDetails.lastName }}
          </h2>
          <br />

          <div class="chat-container" id="chat-container">
            <div v-for="(group, day) in Object.keys(groupedStudentPopUpMessages)" :key="day">

              <div>
                <div style="text-align: center"><span
                    style="border-radius: 30px;background-color: #dbcbcb;font-size: 17px;">{{ formatDayHeader(group)
                    }}</span></div>
                <div v-for="message in groupedStudentPopUpMessages[group]" :key="message._id">

                  <div class="message" style="margin: 0px;" v-if="message.fromUserType == 'Teacher'">
                    <div class="message sender-message" style="margin: 0px;width: 300px;margin-top: -2px;">{{ message.message }}
                      <span class="timestamp">
                        {{ formatTime12Hour(message.date) }}
                      </span>
                    </div>
                  </div>
                  <div class="message receiver-message" v-else style="width: 300px;">
                    <div>{{ message.message }}</div>
                    <span class="timestamp">
                      {{ formatTime12Hour(message.date) }}
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9">
              <textarea style="width: 100%" v-model="studentMessage" @keyup.enter="handleKeyPressTostudent"
                class="message-input" placeholder="Type your message..."></textarea>


            </div>
            <div class="col-lg-2" style="text-align: center;">
              <button style="margin-top: 12px;padding: 19px;margin-left: -14px;" class="send-button"
                @click.prevent="sendToStudent">
                Send
              </button>
            </div>
            <div class="col-lg-1" style="text-align: center">
              <li class="addropdown">
                <span class="dropdown-toggle" data-bs-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                  <button style="margin-top: 12px; padding: 19px;margin-left: -33px;background-color: gray;"
                    class="send-button">
                    ...
                  </button>
                </span>
                <div class="addropdown-content wow bounceIn">
                  <div class="arrow-up"></div>
                  <div class="addropmenu" style="background-color: #dbefe3;width: 311px;">
                    <span class="usernamemob">.sss..</span>
                    <label class="web-custradio" style="margin-right:10px;">Press enter to send message
                      <input type="radio" name="regRadio" @change="showOptions" v-model="isEnterBtnEnable"
                        value="enter" />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio">Click send to send message
                      <input type="radio" name="regRadio" @change="showOptions" v-model="isEnterBtnEnable"
                        value="send" />
                      <span class="web-checkmark"></span>
                    </label>


                  </div>
                </div>
              </li>

            </div>
          </div>

        </div>
      </b-modal>
    </div>
    <ViSpinner v-if="viLoader" text="Loading..." textColor="vi-brand-color" class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;" />
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-awesome/icons/user";
import { socket } from "../../configuration/socketio";
import ViSpinner from "../Common/ViSpinner";

// JavaScript to scroll the chat container to the bottom
// var chatContainer = document.getElementById('chat-container');
// chatContainer.scrollTop = chatContainer.scrollHeight;
export default {
  name: "TeacherHome",
  data() {
    return {
      timestamp: "",
      fullTimeStamp: "",
      fullTimeStampDay: "",
      birthdayStudentList: [],
      birthdayStudentAllList: [],
      holidayList: [],
      studentsearchWords: '',
      studentMessageList: [],
      isEnterBtnEnable: 'send',
      viLoader: false,
      showteacherMessage: false,
      showloader: false,
      allHolidayList: [],
      clockInBody: {
        userId: null,
        notes: "",
      },
      messageDetails: {
        class: null,
        classRoom: null,
      },
      studentMessage: '',
      teacherMessageList: [],
      clockOutBody: {},
      isClockedIn: false,
      clockedInTime: null,
      isClockedOut: false,
      clockedOutTime: null,
      minDatesLeaveApply: null,
      maxDatesLeaveApply: null,
      leaveDetails: {
        from: null,
        to: null,
        notes: '',
        isHalfDay: false,
        toDayType: 'Full',
        fromDayType: 'Full',
        reason: 'Planned Leave'
      },
      dayList: [],
      schoolDayObj: {},
      showAdminChat: false,
      studentList: [],
      sectionList: [],
      classList: [],
      // studentList: [],
      studentDetails: {},
      staffSearch: '',
      // studentMessage: "",
      // adminMessage: "",


      teacherDetails: {},
      staffList: [],
      // searchWords: '',
      userCount: 0,
      userPresentCount: 0,
      studentTotalCount: 0,
      studentPresentCount: 0,
      studentAbsentCount: 0,
      staffLeaveApply: 0,
      studentLeaveApply: 0,
      attendanceRegularization: 0
      // clockedInDiffTime: null,
    };
  },
  watch: {

    getUnreadMessage() {
      let data = []
      for (let x of this.staffList) {
        if (x.message && x.message.length) {
          data = data.concat(x.message)
        }

      }
      return data.length
      //let data = this.staffList
    },
    staffSearch: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || '';
      let hasOldParam = oldVal || '';

      if (hasCurrentParam != hasOldParam) {

        this.getAssignedTeacherDepartmetList();
      }
    },
    studentsearchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.getStudentList();
      }
    },
  },
  created() {
    setInterval(this.getNow, 1000);
    this.getBirthdayStudentList();
    this.getHolidayList();
    this.getClockInInfo();
    this.staffCountAndStudentCounts()
    this.getAssignedTeacherDepartmetList()
    this.getAccountDetails();
    this.getClassWithSection()
    this.getStudentList();

    socket.on('new_mesage_teacher_admin', (message) => {

      if (message) {
        if (this.teacherDetails && this.teacherDetails.userId && this.teacherDetails.userId._id == message.teacher) {
          this.teacherMessageList.push(message)

          let obj = {
            details: {
              admin: message.admin,
              teacher: message.teacher
            },
            data: {
              isAdminView: true
            }
          }

          socket.emit('admin-message-read-teacher', obj);

          var checkContainerId = document.getElementById('chat-container');

          if (checkContainerId) {
            setTimeout(() => {
              var chatContainer = document.getElementById('chat-container');
              chatContainer.scrollTop = chatContainer.scrollHeight
            }, 100);
          } else {
            for (let x of this.staffList) {
              if (message.teacher == x.userId._id) {
                x.message.push(message)
              }
            }
          }


        } else {
          for (let x of this.staffList) {
            if (message.teacher == x.userId._id) {
              x.message.push(message)
            }
          }
        }
      }
    });
    socket.on("new_mesage_student", (message) => {

      if (message) {

        if (
          this.studentDetails &&
          this.studentDetails.parent &&
          this.studentDetails.parent._id == message.userId
        ) {
          this.studentMessageList.push(message);

          let obj = {
            details: {
              userId: message.userId,
              teacher: message.teacher,
            },
            data: {
              isTeacherView: true,
            },
          };

          socket.emit("student-message-read-teacher", obj);
          var checkContainerId = document.getElementById("chat-container");

          if (checkContainerId) {
            setTimeout(() => {
              var chatContainer = document.getElementById("chat-container");
              chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 100);
          } else {
            for (let x of this.studentList) {

              if (message.userId == x.parent._id) {
                x.message.push(message);
              }
            }
          }
        } else {
          for (let x of this.studentList) {

            if (message.userId == x.parent._id) {
              x.message.push(message);
            }
          }
        }
      }
    });

  },

  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 100 + "px",
      };
    },
    groupedStudentMessages() {
      const grouped = {};
      this.teacherMessageList.forEach((message) => {
        const day = this.formatDay(message.date);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });
      return grouped;
    },
    groupedStudentPopUpMessages() {
      // Group messages by day
      const grouped = {};
      this.studentMessageList.forEach((message) => {
        const day = this.formatDay(message.date);
        if (!grouped[day]) {
          grouped[day] = [];
        }
        grouped[day].push(message);
      });
      return grouped;
    },
    noOfDaysLeave() {

      if (this.leaveDetails.from) {
        let dates = []
        let currentDate = null 
        if(this.leaveDetails.isHalfDay){
           currentDate = new Date(this.leaveDetails.from);

        }else{
           currentDate = new Date(this.leaveDetails.to);

        }
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        // this.currentMonth = currentDate.getMonth() + 1;

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long"
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
        console.log("dates",dates)
        if(this.leaveDetails.isHalfDay){
             dates.forEach((x, i) => {
              x['noDays'] = this.leaveDetails.fromDayType == 'Full' ? 1 : 0.5
                
            });
        }else{
          dates.forEach((x, i) => {
            if( i == 0){
              x['noDays'] = this.leaveDetails.fromDayType == 'Full' ? 1 : 0.5
            }else if(dates.length - 1){
              x['noDays'] = this.leaveDetails.toDayType == 'Full' ? 1 : 0.5
            }else{
              x['noDays'] = 1
            }

                 // x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full';
          });
        }
        let totalDays = dates.map((o) => Number(o.noDays))
              .reduce((a, c) => {
                return a + c;
              });
              return  totalDays
       // return dates.length

      } else {
        return 0
      }


    },

    clockedInDiffTime() {

      if (this.clockOutBody.clockInTime && this.clockOutBody.clockOutTime) {
        var hour1 = this.clockOutBody.clockedInTimeValue.split(':')[0];
        var hour2 = this.clockOutBody.clockedOutimeValue.split(':')[0];
        var min1 = this.clockOutBody.clockedInTimeValue.split(':')[1];
        var min2 = this.clockOutBody.clockedOutimeValue.split(':')[1];

        var diff_hour = hour2 - hour1;
        var diff_min = min2 - min1;
        if (diff_hour < 0) {
          diff_hour += 24;
        }
        if (diff_min < 0) {
          diff_min += 60;
          diff_hour--;
        } else if (diff_min >= 60) {
          diff_min -= 60;
          diff_hour++;
        }
        // const timeDifferenceInMilliseconds = new Date(this.clockOutBody.clockOutTime) - new Date(this.clockOutBody.clockInTime);

        // const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
        // const minutes = Math.floor(seconds / 60);
        // const hours = Math.floor(minutes / 60);
        // const days = Math.floor(hours / 24);

        return `${diff_hour % 24}hrs : ${diff_min % 60}mins`;
      } else {
        return "";
      }

      // return this.clockedInTime == null ? "0h:00m" : "0h:00m";
    },
  },
  // watch: {
  //   searchWords: function (currentVal, oldVal) {
  //     let hasCurrentParam = currentVal || '';
  //     let hasOldParam = oldVal || '';

  //     if (hasCurrentParam != hasOldParam) {
  //       this.getAssignedTeacherDepartmetList();
  //     }
  //   }
  // },

  methods: {
    openLeavePopUp() {
      // var newDate = new Date().add(30).days();

      var currentDate1 = new Date();
      var currentDate2 = new Date();
      var numberOfDaysToAdd = 30;


      let minDatesLeave = new Date(currentDate1.setDate(currentDate1.getDate() - numberOfDaysToAdd))

      this.minDatesLeaveApply = this.dateFormat(minDatesLeave)

      let maxDatesLeave = new Date(currentDate2.setDate(currentDate2.getDate() + numberOfDaysToAdd))

      this.maxDatesLeaveApply = this.dateFormat(maxDatesLeave)

      this.$bvModal.show("leaveApplyPopUp");

    },
    checkIsHalfDay(){

    },
    checkToDate() {
      this.leaveDetails.to = null
    },
    dateFormat(value) {
      if (value) {
        let date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
    },
    openChat(data) {
      (this.studentDetails = data),

        (this.studentMessageList = []),

        this.$bvModal.show("messageStudentPopUp");
      this.getStudentMessageList(this.studentDetails.parent._id);

      if (data.message.length > 0) {
        this.getStudentReadMessageList(this.studentDetails.parent._id);
      }

      setTimeout(() => {
        var chatContainer = document.getElementById("chat-container");
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 100);
    },
    async getStudentReadMessageList(studentId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          details: {
            userId: studentId,
            teacher: userData.userDetails._id,
          },
          data: {
            isTeacherView: true,
          },
        };

        socket.emit("student-message-read-teacher", obj);

        for (let x of this.studentList) {
          if (studentId == x.parent._id) {
            x.message = [];
          }
        }
      }
    },
    async getStudentMessageList(studentId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/home/getStudentTeacherMessage/${userData.userDetails._id}/${studentId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.studentMessageList = secureUI.secureGet(response.data);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async sendToStudent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
          student: this.studentDetails._id,
          userId: this.studentDetails.parent._id,
          teacher: userData.userDetails._id,
          message: this.studentMessage,
          fromUserType: "Teacher",
          isTeacherView: true,
          isStudentView: false,
          date: new Date(),
        };

        socket.emit("teacher-message-student", obj);

        this.studentMessageList.push(obj);

        this.studentMessage = "";

        setTimeout(() => {
          var chatContainer = document.getElementById("chat-container");
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);
      }
    },
    closeStudentChat() {
      this.$bvModal.hide("messageStudentPopUp");
      this.studentDetails = {}
    },
    closeLeavePopUp() {
      this.$bvModal.hide("leaveApplyPopUp");
      this.leaveDetails.from = null
      this.leaveDetails.to = null
      this.leaveDetails.notes = '',
        this.leaveDetails.reason = 'Planned Leave',
        this.leaveDetails.toDayType = 'Full',
        this.leaveDetails.fromDayType = 'Full'
        this.leaveDetails.isHalfDay = false

        

      //reason: 'Planned Leave',

    },
    handleKeyPress(event) {

      if (event.key === 'Enter' && this.isEnterBtnEnable == 'enter') {
        this.sendToTeacher();
      }
    },
    handleKeyPressTostudent(event) {

      if (event.key === 'Enter' && this.isEnterBtnEnable == 'enter') {
        this.sendToStudent();
      }
    },


    showOptions() {

      this.updateUser()
      localStorage.setItem("messageAction", this.isEnterBtnEnable);

    },
    async updateUser() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let requestBody = {
          _id: userData.userDetails._id,
          isEnterBtnEnable: this.isEnterBtnEnable
        };
        const response = await ViService.viXPost(
          "/user/updateMessageUser",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {

        } else {
          this.$toasted.error(response.message);
        }

      }
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        //this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
          userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          if (details.workingDayList && details.workingDayList.length > 0) {
            this.dayList = []

            const filteredObjects = details.workingDayList.filter(obj => obj.isselected);

            if (filteredObjects.length) {
              this.dayList = filteredObjects.map(obj => obj.name);

              const groupByDay = this.dayList.reduce((r, a) => {
                r[a] = a; // set the id
                return r;
              }, {});

              this.schoolDayObj = groupByDay


            }
          }

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        ///this.viLoader = false;
      }
    },
    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");


      let getClassCounts = this.$store.getters.getClassAndSectionList;

      if (getClassCounts.length == 0) {
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXGet(
            `/academic/class_with_sections`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
            this.$store.dispatch("setClassAndSectionList", resultData.data);
            this.classList = resultData.data;
          } else {
            this.$toasted.error(response.message);
          }
        }
      } else {
        this.classList = this.$store.getters.getClassAndSectionList;
      }

      if (this.classList.length > 0) {
        //this.messageDetails.class = this.classList[0]._id;
        //await this.getSection(this.messageDetails.class);
      }

    },
    getSection(classId) {
      this.messageDetails.classRoom = null
      this.getStudentList();

      let tempSections = [];
      //  const letters = new Set();
      let allSections = this.classList.find((x) => x._id == classId).sections;

      // let userData = secureUI.sessionGet("user");

      // if (userData.userDetails.profile.name == "Teacher") {
      //   let sections = allSections.filter((x) => x.assignedTeachers.length > 0);

      //   if (sections.length > 0) {
      //     for (let x of sections) {
      //       for (let y of x.assignedTeachers) {
      //         if (y.teacherId && y.teacherId == userData.userDetails._id) {
      //           // this.assignedSubjects.push(y.subjectId)
      //           tempSections.push(x._id);
      //         }
      //       }
      //     }
      //     // Create a Set from the array to remove duplicates
      //     let uniqueSet = new Set(tempSections);

      //     // Convert the Set back to an array
      //     let uniqueArray = Array.from(uniqueSet);

      //     this.sectionList = sections.filter((s) =>
      //       uniqueArray.includes(s._id)
      //     );
      //   }
      // } else {
      this.sectionList = allSections;
      // }
      // if (this.sectionList.length > 0) {
      //  // this.messageDetails.classRoom = this.sectionList[0]._id;
      //   this.getStudentList();
      // }
    },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
      //  if (this.messageDetails.class && this.messageDetails.classRoom) {
          const response = await ViService.viXGet(
            `/contact/view/getStudentListForMessage?classId=${this.messageDetails.class}&sectionId=${this.messageDetails.classRoom}&search=${this.studentsearchWords}`,
            userData.token
          );
          if (response.isSuccess) {
          //  this.studentList = 
            let data = secureUI.secureGet(response.data);

            data.sort((a, b) => b.message.length - a.message.length);
            this.studentList = data;

            //  this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        //}
      }
    },
    openAdminChat(data) {


      this.teacherDetails = data,

        this.teacherMessageList = [],
        this.$bvModal.show("messageTeacherPopUp");
      this.getTeacherAndAdminMessageList(this.teacherDetails.userId._id)
      this.getReadMessageList(this.teacherDetails.userId._id)

      // setTimeout(() => {
      //   var chatContainer = document.getElementById('chat-container');
      //   chatContainer.scrollTop = chatContainer.messageTeacherPopUp___BV_modal_content_
      //  }, 100);
      setTimeout(() => {
        // var chatContainer = document.getElementById('messageTeacherPopUp___BV_modal_content_');
        // chatContainer.style.width = '60%'
        var chatContainer = document.getElementById('chat-container');
        chatContainer.scrollTop = chatContainer.scrollHeight
        // var chatContainer = document.getElementById('messageTeacherPopUp___BV_modal_content_');
        // chatContainer.style.width = '60%'
        // console.log("chatContainer",chatContainer)
        this.showteacherMessage = true
      }, 400);


    },
    async getReadMessageList(teacherId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        let obj = {
          details: {
            teacher: teacherId,
            admin: userData.userDetails._id
          },
          data: {
            isAdminView: true
          }
        }

        socket.emit('admin-message-read-teacher', obj);

        for (let x of this.staffList) {
          if (teacherId == x.userId._id) {
            x.message = []
          }
        }

      }
    },
    async getTeacherAndAdminMessageList(staffId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        const response = await ViService.viXGet(`/home/getTeacherAndAdminMessage/${userData.userDetails._id}/${staffId}`, userData.token);
        if (response.isSuccess) {
          this.teacherMessageList = secureUI.secureGet(response.data);

        } else {
          this.$toasted.error(response.message);
          if (response.message == 'Your session has expired, please login') {
            localStorage.removeItem('user');
            this.$router.push('/login');
          }
        }
      }
    },

    async sendToTeacher() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        let obj = {
          student: null,
          admin: userData.userDetails._id,
          teacher: this.teacherDetails.userId._id,
          message: this.studentMessage,
          fromUserType: 'Admin',
          isTeacherView: false,
          isAdminView: true,
          date: new Date()
        }
        socket.emit('admin-message-teacher', obj);

        this.teacherMessageList.push(obj);

        this.studentMessage = '';

        setTimeout(() => {
          var chatContainer = document.getElementById('chat-container');
          chatContainer.scrollTop = chatContainer.scrollHeight
        }, 100);

        // admin-message-teacher

        // const response = await ViService.viXPost("/home/saveMessageAdminToTeacher",obj,userData.token);

        // if (response.isSuccess) {
        //   this.studentMessage = ''
        //   this.getTeacherAndAdminMessageList(this.teacherDetails.userId._id)
        //   setTimeout(() => {
        //   var chatContainer = document.getElementById('chat-container');
        //   chatContainer.scrollTop = chatContainer.scrollHeight
        //  }, 100);
        // //this.$toasted.success(response.message);
        // } else {
        // this.$toasted.error(response.message);

        // }
      }

    },
    closeTeacherChat() {
      this.$bvModal.hide("messageTeacherPopUp");
      this.teacherDetails = {}
    },
    formatTime12Hour(data) {
      let date = new Date(data)
      // Extract hours, minutes, and AM/PM indicator
      const hours = date.getHours() % 12 || 12; // Ensure 12-hour format
      const minutes = date.getMinutes();
      const period = date.getHours() < 12 ? 'AM' : 'PM';

      // Format as HH:MM AM/PM
      const formattedTime = `${hours}:${(minutes < 10 ? '0' : '')}${minutes} ${period}`;

      return formattedTime;
    },
    async updateLeaveApply() {


      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        if(this.leaveDetails.isHalfDay){
          this.leaveDetails.to = this.leaveDetails.from
        }

        let dates = []

        const currentDate = new Date(this.leaveDetails.to);
        const dd = new Date(this.leaveDetails.from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        // this.currentMonth = currentDate.getMonth() + 1;

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long"
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
        
        if(this.leaveDetails.isHalfDay){
             dates.forEach((x, i) => {
              x['isFullDayLeave'] = this.leaveDetails.fromDayType == 'Full' ? true : false
              x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full'
              x['noDays'] = this.leaveDetails.fromDayType == 'Full' ? 1 : 0.5
                
            });
        }else{
          dates.forEach((x, i) => {
            if( i == 0){
              x['isFullDayLeave'] = this.leaveDetails.fromDayType == 'Full' ? true : false
              x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full'
              x['noDays'] = this.leaveDetails.fromDayType == 'Full' ? 1 : 0.5

            }else if(dates.length - 1){
              x['isFullDayLeave'] = this.leaveDetails.toDayType == 'Full' ? true : false
              x['isDayStatus'] = this.leaveDetails.toDayType || 'Full'
              x['noDays'] = this.leaveDetails.toDayType == 'Full' ? 1 : 0.5

            }else{
              x['isFullDayLeave'] = true
              x['isDayStatus'] = 'Full'
              x['noDays'] = 1

            }

                 // x['isDayStatus'] = this.leaveDetails.fromDayType || 'Full';
          });
        }


        let obj = {
          from: this.leaveDetails.from,
          to: this.leaveDetails.to,
          notes: this.leaveDetails.notes,
          reason: this.leaveDetails.reason,
          isHalfDay: this.leaveDetails.isHalfDay || false,
          toDayType: this.leaveDetails.toDayType || 'Full',
          fromDayType: this.leaveDetails.fromDayType || 'Full',
          noOfDates: dates,
          type: 'Staff Leave',
          status: 'Pending Approval'
        }
        console.log("obj",dates)
        const response = await ViService.viXPost(
          "/home/leave/apply/staff",
          obj,
          userData.token
        );


        if (response.isSuccess) {
          this.closeLeavePopUp()
          this.$toasted.success("Leave Applied Successfully");
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    moveRightSide() {
      document.getElementById("HolidayPic").scrollLeft += 222;
    },
    moveLeftSide() {
      document.getElementById("HolidayPic").scrollLeft -= 222;
    },
    getNow() {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

        // var newDate = new Date();
          var hour = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
          var minute = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
          var second = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
          var meridian = today.getHours() < 12 ? "AM" : "PM";

          // this.viLoader = true;

         // this.clockInBody['clockedInTimeLocalValue'] = hour + ":" + minute + ":" + second + " " + meridian;


      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = hour + ":" + minute + ":" + second + " " + meridian;
      this.fullTimeStamp = today.getDate() + "  " + months[today.getMonth()] + "  " + today.getFullYear();
      this.fullTimeStampDay = weekday[today.getDay()]
    },
    navToAttendance() {
      console.log("in nav to attendance ==> ");
      this.$router.push("/admin/attendance/management");
    },
    navToLeave() {
      console.log("in nav to attendance ==> ");
      this.$router.push("/teacher/admin/leave/management/view");
    },
    openBirthdaypopup() {
      this.$bvModal.show("birthDayPopUp");
    },
    closeHolidayPopUp() {
      this.allHolidayList = []

      this.$bvModal.hide("holidayPopUp");
    },
    openHolidaypopup() {
      this.allHolidayList = []
      this.getAllHolidayList()
      this.$bvModal.show("holidayPopUp");
    },
    closeBirthDayPopUp() {
      this.$bvModal.hide("birthDayPopUp");
    },

    async getBirthdayStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/birthday/student",
          userData.token
        );
        if (response.isSuccess) {
          const today = new Date();
          var todayDate = today.getDate();
          var result = secureUI.secureGet(response.data);
          this.birthdayStudentList = result.todayBirthDays || [],
            this.birthdayStudentAllList = result.allBirthDays || []


          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    redirectRoute(vipath) {
      //  currentRoute = this.$route.path
      // }
      localStorage.setItem("previousTab", this.$route.path);
      localStorage.setItem("activeTab", vipath);
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    formatDayHeader(day) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (day === this.formatDay(today)) {
        return "Today";
      } else if (day === this.formatDay(yesterday)) {
        return "Yesterday";
      } else {
        return day;
      }
    },
    formatDay(timestamp) {
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    formatTime(sss) {
      let timestamp = new Date(sss);
      if (timestamp) {
        return `${timestamp.getHours()}:${timestamp.getMinutes() < 10 ? "0" : ""
          }${timestamp.getMinutes()}`;
      } else {
        return "";
      }
    },
    async staffCountAndStudentCounts() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/staffCountAndStudentCounts",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
console.log("resultresultresultresult",JSON.stringify(result.userCsssssssount))
          this.userCount = (result.userCount - result.userPrsenteCount),
            this.userPresentCount = result.userPrsenteCount,
            this.studentTotalCount = result.student,
            this.studentPresentCount = result.studentPresentCount,
            this.studentAbsentCount = result.studentAbsentCount,
            this.staffLeaveApply = result.staffLeaveApply,
            this.studentLeaveApply = result.studentLeaveApply,
            this.attendanceRegularization = result.attendanceRegularization
          // this.birthdayStudentList = result

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    // openAdminChat(data) {
    //   this.showAdminChat = true;
    // },

    async getAssignedTeacherDepartmetList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //  if(value){
        //    this.viLoader = true;
        //  }

        const response = await ViService.viXGet(
          `/teacher/getAssignedTeacherForMessageList?search=${this.staffSearch}`,
          userData.token
        );
        if (response.isSuccess) {
          const finalData = secureUI.secureGet(response.data);
          let data = finalData

          data.sort((a, b) => b.message.length - a.message.length);
          this.staffList = data;
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
          // this.staffList = this.staffList.concat(this.staffList)
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        // this.viLoader = false;
      }
    },
    async getHolidayList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/holiday",
          userData.token
        );
        if (response.isSuccess) {
          this.holidayList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getAllHolidayList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/all/holiday",
          userData.token
        );
        if (response.isSuccess) {
          this.allHolidayList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async clockIn() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let clockinLocationObj = {}
        this.showloader = true
        await navigator.geolocation.getCurrentPosition((position) => {

          clockinLocationObj['latitude'] = position.coords.latitude,
            clockinLocationObj['longitude'] = position.coords.longitude

          this.clockInBody['clockinLocation'] = clockinLocationObj;

          this.callclockIn()

        });


      }
    },
    async callclockIn() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        if (this.clockInBody['clockinLocation'] && this.clockInBody['clockinLocation'].longitude) {
          // this.clockInBody['clockinLocation'] = clockinLocationObj;


          // this.viLoader = true;
          var newDate = new Date();
          var hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
          var minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
          var second = newDate.getSeconds() < 10 ? "0" + newDate.getSeconds() : newDate.getSeconds();
          var meridian = newDate.getHours() < 12 ? "AM" : "PM";

          // this.viLoader = true;

          this.clockInBody['clockedInTimeValue'] = hour + ":" + minute;
          this.clockInBody['clockedInTimeLocalValue'] = hour + ":" + minute + " " + meridian;

          this.clockInBody.userId = null;
          this.clockInBody.notes = "";

          const response = await ViService.viXPost(
            "/home/clockin/save",
            this.clockInBody,
            userData.token
          );

          // this.viLoader = null;

          if (response.isSuccess) {
            this.isClockedIn = true;
            this.clockOutBody = response.data;

            this.clockedInTime = hour + ":" + minute + ":" + second + "" + meridian;
            this.$toasted.success("Clocked in successfully");
          } else {
            this.$toasted.error(response.message);
          }
        }
        this.showloader = false

      }
    },
    async clockOut() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let clockinLocationObj = {}

        await navigator.geolocation.getCurrentPosition((position) => {

          clockinLocationObj['latitude'] = position.coords.latitude,
            clockinLocationObj['longitude'] = position.coords.longitude

          this.clockOutBody['clockoutLocation'] = clockinLocationObj;
          this.showloader = true
          this.callclockOut()

        });
      }
    },
    async callclockOut() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        var newDate = new Date();
        var hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
        var minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
        var second = newDate.getSeconds() < 10 ? "0" + newDate.getSeconds() : newDate.getSeconds();
        var meridian = newDate.getHours() < 12 ? "AM" : "PM";

        // this.viLoader = true;

        this.clockOutBody['clockedOutimeValue'] = hour + ":" + minute;
        this.clockOutBody['clockedOutTimeLocalValue'] = hour + ":" + minute + " " + meridian;

        const response = await ViService.viXPost(
          "/home/clockout/save",
          this.clockOutBody,
          userData.token
        );

        //  this.viLoader = null;

        if (response.isSuccess) {
          this.getClockInInfo();
          this.$toasted.success("Clocked Out successfully");
        } else {
          this.$toasted.error(response.message);
        }

        this.showloader = false
      }
    },
    async getClockInInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/clockin/info",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          if (Object.keys(result).length === 0) {
            //this.clockedInTime = result.clockInTime;
          } else {

            this.clockOutBody = result;

            if (result.clockInTime && result.status != 'Leave' && result.status != 'Pending Approval') {
              this.isClockedIn = true;
              var newDate = new Date(result.clockInTime);

              var hour =
                newDate.getHours() < 10
                  ? "0" + newDate.getHours()
                  : newDate.getHours();
              var minute =
                newDate.getMinutes() < 10
                  ? "0" + newDate.getMinutes()
                  : newDate.getMinutes();
              var second =
                newDate.getSeconds() < 10
                  ? "0" + newDate.getSeconds()
                  : newDate.getSeconds();
              var meridian = newDate.getHours() < 12 ? "AM" : "PM";
              this.clockedInTime =
                hour + ":" + minute + ":" + "" + meridian;
            }

            if (result.clockOutTime) {
              var newDate = new Date(result.clockOutTime);
              var hour =
                newDate.getHours() < 10
                  ? "0" + newDate.getHours()
                  : newDate.getHours();
              var minute =
                newDate.getMinutes() < 10
                  ? "0" + newDate.getMinutes()
                  : newDate.getMinutes();
              var second =
                newDate.getSeconds() < 10
                  ? "0" + newDate.getSeconds()
                  : newDate.getSeconds();
              var meridian = newDate.getHours() < 12 ? "AM" : "PM";
              this.clockedOutTime =
                hour + ":" + minute + ":" + "" + meridian;
              this.isClockedIn = true;
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
  },
  components: {
    ViSpinner
  },
};
</script>
<style>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chat-container {


  margin-top: -9px;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  /* Set a fixed height for the chat container */
  overflow-y: scroll;
  /* Enable vertical scrolling */

}

/* Style the label when it is clicked/selected */
input[type="radio"]:checked+label {
  background: #2954A2;
  /* background-color: #e0e0e0; */
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  clear: both;
  overflow: hidden;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.user-message {
  background-color: #e0f7fa;
}

.other-message {
  background-color: #fff;
}

.sender-message {
  background-color: #e0f7fa;
  float: right;
}

.receiver-message {
  background-color: #e9d1d1;
  float: left;
}

.message-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: none;
}

.send-button {
  background-color: #2954A2;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.timestamp {
  font-size: 12px;
  float: right;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

/* Hide the radio button */
input[type="radio"] {
  display: none;
}

/* Style the label to make it look clickable */
.custLabel {
  cursor: pointer;
  padding: 5px;
  background: beige;
  /* // border: 1px solid #ccc; */
  display: inline-block;
  margin: 5px;
  font-size: 0.89rem;
  border-radius: 10rem;
  /* color: #fff; */
  padding: 0.39rem 1.3rem 0.3rem 1.3rem;
  /* width: 140px; */
}

#messageTeacherPopUp .modal-content {
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}

#messageStudentPopUp .modal-content {
  width: 50% !important;
  /* Could be more or less, depending on screen size */
}

#leaveApplyPopUp .modal-content {
  width: 57% !important;
  /* Could be more or less, depending on screen size */
}
</style>
