<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <!-- <div id="page-content" v-if="!viLoader">
         <div class="dispflex" style="margin-top: 4%;">
        <h1 class="hdrtitle" style="margin-top: 0px;">Vidhyaan Feature Request</h1> -->
    <div id="page-content" v-if="!viLoader" class="m-3">
         <div class="webNew-dispflex" style="align-items:center;padding-top:10px;">
          <!-- <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button> -->
        <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
        <h1 class="hdrtitle" style="margin-bottom:0;margin-top: 0px;">Vidhyaan Feature Request</h1>
        <button
          type="button"
          style="width: 12%;margin-top:6px;height:38px;margin-left: 52%;"
          class="btn btnsml"
          id="svbtn"
          @click="openIdeaPopUp"
        >
          Submit Idea
        </button>
      
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
           
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input checked="checked"
                  type="radio"
                  name="tabs"
                  id="newtab"
                  
                />
                <label for="newtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">New [{{ newFeatureList.length }}]</label>
                <div class="tab">
                  <div class="row mt-3">
                    <div class="col-sm-6 col-lg-3" style="margin-top: 1%;" v-for="(item, index) in newFeatureList" :key="index" >
                    <div class="white-box org mb-2" style="height: 100%;background-color: #ece2de" @click="viewIdea(item._id,item.viewCount,index)">
                        <div ><span style="font-weight: bold;">{{ item.title }}</span><span style="padding-left: 3%;"><i class="fa fa-user pr-2"></i>{{ item.viewCount }}</span>
                        </div>
                        <p class="widgettitle"> {{ item.description }} ....
                        
                        </p>
                    </div>
                </div>
       
              </div>
                </div>
                <input 
                  type="radio"
                  name="tabs"
                  id="academictab"
                  
                />
                <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" for="academictab">UNDER CONSIDERATION [{{ underConsiderationFeatureList.length }}]</label>
                <div class="tab">
                  <div class="row mt-3">
                    <div class="col-sm-6 col-lg-3" style="margin-top: 1%;" v-for="(item, index) in underConsiderationFeatureList" :key="index" >
                    <div class="white-box org mb-2" style="height: 100%;background-color: #f7f7f7;border:1px solid #e9e9e9" @click="viewIdea(item._id,item.viewCount,index)">
                        <div class="dispflex"><span style="font-weight: bold;">{{ item.title }}</span><span style="padding-left: 3%;"><i class="fa fa-user pr-2"></i><span style="font-weight: bold;">{{ item.viewCount }}</span></span>
                        </div>
                        <p  class="multiline-ellipsis"> {{ item.description }} ....
                        
                        </p>
                    </div>
                </div>
       
              </div>
                </div>
                
                <input type="radio" name="tabs" id="departtab"  />
                <label for="departtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">PLANNED [{{ plannedFeatureList.length }}]</label>
                <div class="tab">
                   <div class="row mt-3">
                    <div class="col-sm-6 col-lg-3"  v-for="(item, index) in plannedFeatureList" :key="index" >
                    <div class="white-box org mb-2" style="height: 100%;background-color: #ece2de;">
                        <div ><span style="font-weight: bold;">{{ item.title }}</span><span style="padding-left: 3%;"><i class="fa fa-user pr-2"></i>{{ item.viewCount }}</span>
                         </div>
                        <p class="widgettitle"> {{ item.description }} ....
                        
                        </p>
                    </div>
                </div>
       
              </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="grandlogintab"
                />
                <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" for="grandlogintab">IN PROGRESS [{{ inProgressFeatureList.length }}]</label>

                <div class="tab">
                  <div class="row mt-3">
                    <div class="col-sm-6 col-lg-3"  v-for="(item, index) in inProgressFeatureList" :key="index" >
                    <div class="white-box org mb-2" style="height: 100%;background-color: #ece2de;" >
                        <div ><span style="font-weight: bold;">{{ item.title }}</span><span style="padding-left: 3%;"><i class="fa fa-user pr-2"></i>{{ item.viewCount }}</span>
                        </div>
                        <p class="widgettitle"> {{ item.description }} ....
                        
                        </p>
                    </div>
                </div>
       
              </div>
                  <div class="row mt-5">
                    <div class="col-lg-10"></div>
                    <div class="col-lg-2">
                      <div class="dk_iconsml">
                       
                      </div>
                    </div>
                  </div>
                </div>
                 <input
                  type="radio"
                  name="tabs"
                  id="lanchedtab"
                />
                <label for="lanchedtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">LAUNCHED [{{ lanuchedFeatureList.length }}]</label>

                <div class="tab">
                  <div class="row mt-3">
                    <div class="col-sm-6 col-lg-3"  v-for="(item, index) in lanuchedFeatureList" :key="index" >
                    <div class="white-box org mb-2" style="height: 100%;background-color: #ece2de;">
                        <div ><span style="font-weight: bold;">{{ item.title }}</span><span style="padding-left: 3%;"><i class="fa fa-user pr-2"></i>{{ item.viewCount }}</span>
                        </div>
                        <p class="widgettitle"> {{ item.description }} ....
                        
                        </p>
                    </div>
                </div>
       
              </div>
                  <div class="row mt-5">
                    <div class="col-lg-10"></div>
                    <div class="col-lg-2">
                      <div class="dk_iconsml">
                       
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal id="ideaPopUp" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closeSubmitPopUp">×</button>

      <div class="contact-form">
        <h2>Create your idea</h2>
        <br />
        <div>
          <form data-vv-scope="departConfig" accept-charset="utf-8">
            <div class="form-row">
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="featureDetails.title"
                    name="title"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="title"
                  />
                  <label
                    v-if="errors.first('departConfig.title')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Title is required</label
                  >
                  <label v-else for="Start">Title </label>
                </span>
              </div>
                 <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                    <select
                          name="Format"
                          v-model="featureDetails.stage"
                          class="form-control form-input"
                          id="Format"
                        >
                          <option value="">--Select--</option>
                          <option
                            v-for="(item, index) in priorityList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                 
                  <label  for="Format">Priority</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="featureDetails.email"
                    name="Start"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Start"
                  />
                  <label
                    v-if="errors.first('departConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Email is required</label
                  >
                  <label v-else for="Start">Email </label>
                </span>
              </div>
              <div class="form-group input-group col-md-12 pr-md-3 mt-3">
                <span class="has-float-label">
                     <textarea 
                        class="form-control web-form-input"
                        style="min-height:140px;resize:none"
                        id="description"
                        v-validate="'required'"
                        v-model="featureDetails.description"
                        placeholder="What would you like to be able to do? How would that help you..?"
                        ></textarea>
                 
                  <label
                    v-if="errors.first('departConfig.description')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Please write some ideas</label
                  >
                  <label v-else for="Prefix">Write your idea </label>
                </span>
              </div>
             
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
           
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click="saveIdea"
              >
                  <span>Submit</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeSubmitPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="viewPopUp" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closeViewPopUp">×</button>

      <div class="contact-form">
        <h2>View</h2>
        <br />
        <div>
          <form data-vv-scope="departConfig" accept-charset="utf-8">
            <div class="form-row">
                <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    disabled
                    v-model="editDetails.title"
                    name="title"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="title"
                  />
                  <label
                    v-if="errors.first('departConfig.title')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Title is required</label
                  >
                  <label v-else for="Start">Title </label>
                </span>
              </div>
                 <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                    <select disabled
                          name="Format"
                          v-model="editDetails.stage"
                          class="form-control form-input"
                          id="Format"
                        >
                          <option value="">--Select--</option>
                          <option
                            v-for="(item, index) in priorityList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                 
                  <label  for="Format">Priority</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input disabled
                    v-model="editDetails.email"
                    name="Start"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Start"
                  />
                  <label
                    v-if="errors.first('departConfig.Start')"
                    class="vi-error"
                    for="Start"
                    style="top: -1em"
                    >Email is required</label
                  >
                  <label v-else for="Start">Email </label>
                </span>
              </div>
              <div class="form-group input-group col-md-12 pr-md-3 mt-3">
                <span class="has-float-label">
                     <textarea v-validate="'required'"
                        class="form-control web-form-input"
                        style="min-height:140px;resize:none"
                        id="Prefsssix"
                        disabled
                        v-model="editDetails.description"
                        placeholder="What would you like to be able to do? How would that help you..?"
                        ></textarea>
                  <!-- <input
                    type="text"
                    name="Prefix"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="Prefix"
                    placeholder="What would you like to be able to do? How would that help you..?"
                  /> -->
                  <label
                    v-if="errors.first('departConfig.Prefsssix')"
                    class="vi-error"
                    for="Prefix"
                    style="top: -1em"
                    >Please write some ideas</label
                  >
                  <label v-else for="Prefix">Write your idea </label>
                </span>
              </div>
             
            </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
              <!-- <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click="saveIdea"
              >
                  <span>Submit</span>
              </button> -->

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeViewPopUp"
              >
                   <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
   </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
  </div>
  <!-- </div>
  </div> -->
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      viLoader: false,
      newFeatureList: [],
      underConsiderationFeatureList: [],
      plannedFeatureList : [],
      inProgressFeatureList: [],
      lanuchedFeatureList: [],
      priorityList: [
       'NICE-TO-HAVE',
       'IMPORTANT',
       'CRITICAL'
      ],
      statusList: [
        'New',
        'Under Consideration',
        'Planned',
        'In Progress',
        'Launched'
      ],
      featureDetails: {
          title: '',
          status: '',
          description: '',
          email: '',
          viewCount: 0,
          stage: ''
      },
      editDetails: {
          _id: null,
          title: '',
          status: '',
          description: '',
          email: '',
          viewCount: 0,
          stage: ''
      }
    };
  },
  created() {
   this.getFeatureList();
  },
   computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 95) + 'px'
       };
      }
     
  },

  methods: {
  previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   
    closeSubmitPopUp(){
        this.$bvModal.hide("ideaPopUp");
        this.featureDetails.status = '',
        this.featureDetails.description = '',
        this.featureDetails.email = '',
        this.featureDetails.viewCount = 0,
        this.featureDetails.stage = '',
        this.editDetails.title = ''
    },
    openIdeaPopUp() {
      this.$bvModal.show("ideaPopUp");
    },
    async saveIdea() {
      let isFormValid = false;

      await this.$validator.validateAll("departConfig").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
            this.featureDetails.status = 'Under Consideration'
          //this.featureDetails['submitby'] = ''
          const response = await ViService.viXPost(`/account/submit/featureRequest`,this.featureDetails,userData.token);

          if (response.isSuccess) {
            this.closeSubmitPopUp()
            this.getFeatureList()
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    closeViewPopUp(){
      this.$bvModal.hide("viewPopUp");
      this.getFeatureList()
    },
    async viewIdea(id,count,index) {
      let isFormValid = true;

    //   await this.$validator.validateAll("departConfig").then((result) => {
    //     isFormValid = result;
    //   });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
        //    this.featureDetails.status = 'Under Consideration'
          //this.featureDetails['submitby'] = ''
          let obj = {
           viewCount: count + 1
          }
          const response = await ViService.viXPut(`/account/update/featureRequest/${id}`,obj,userData.token);

          if (response.isSuccess) {
              let details = this.newFeatureList[index]
              
                this.editDetails.status = details.status || '',
                this.editDetails.description = details.description || '',
                this.editDetails.email = details.email || '',
                this.editDetails.viewCount = details.viewCount || 0,
                this.editDetails.stage = details.stage || '',
                this.editDetails.title = details.title || '',
                this.$bvModal.show("viewPopUp");
          
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    
    async getFeatureList() {
      // let userData = secureUI.sessionGet("user");

      // if (!userData) {
      //   this.$toasted.error("Please login and do the action");
      // } else {
         this.viLoader = true
        const response = await ViService.viGetOnly("/app/getFeatureList");
        
        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
           if(details.length > 0){
            this.newFeatureList = details.filter( x => x.status == 'Under Consideration'),
            // this.underConsiderationFeatureList = details.filter( x => x.status == 'Under Consideration'),
            this.underConsiderationFeatureList = [],
            this.plannedFeatureList  = details.filter( x => x.status == 'Planned'),
            this.inProgressFeatureList = details.filter( x => x.status == 'In Progress'),
            this.lanuchedFeatureList = details.filter( x => x.status == 'Launched')
          
         }
       } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.viLoader = false
      //}
    },

    
  },
  components: {
    ViSpinner
  },
};
</script>
